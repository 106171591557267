import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ProviderLicenseType } from "../../redux/api/provider/types";
import {
  useDeleteProviderLicenseMutation,
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useLocalStorage } from "../../common/localStorage";
import {
  LICENSE_CONTENT_DISPLAY_OPTIONS,
  LICENSE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { Header, Pagination, Table } from "@cloudscape-design/components";

export default function LicenseV2() {
  const { providerId } = useParams();
  const [selectedRows, setSelectedRows] = useState<ProviderLicenseType[]>([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [deleteProviderLicense] = useDeleteProviderLicenseMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );

  const { data: licenseType } = useGetAllLicenseTypesQuery();

  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId ? { providerId: providerId } : skipToken
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-license-Table-Preferences",
    LICENSE_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerLicenses ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => item.id || "-",
            isRowHeader: true,
          },
          {
            id: "licenseType",
            header: <div>License Type</div>,
            cell: (item) => (
              <NavLink
                to={
                  hasPermission
                    ? `/manageprovider/${providerId}/license/${item.id}/edit`
                    : `#`
                }
              >
                {item?.licenseType}
              </NavLink>
            ),
            sortingField: "licenseType",
            sortingComparator: (a, b) =>
              (a?.licenseType ?? "").localeCompare(b?.licenseType ?? ""),
          },
          {
            id: "licenseNumber",
            header: <div>License Number</div>,
            cell: (item) => item?.licenseNumber || "-",
          },
          {
            id: "state",
            header: <div>State</div>,
            cell: (item) => <div> {item?.state ?? "-"} </div>,
            sortingField: "state",
            sortingComparator: (a, b) =>
              (a?.state ?? "").localeCompare(b?.state ?? ""),
          },
          {
            id: "startDate",
            header: <div>Start Date</div>,
            cell: (item) =>
              item?.startDate ? (
                <span>
                  <CalendarOutlined /> {IncredableDateFormat(item?.startDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "startDate",
          },
          {
            id: "expirationDate",
            header: <div>Expiration Date</div>,
            cell: (item) =>
              item?.endDate ? (
                <span>
                  <CalendarOutlined /> {IncredableDateFormat(item?.endDate)}
                </span>
              ) : (
                "-"
              ),
            sortingField: "endDate",
          },
          {
            id: "notes",
            header: <div>Notes</div>,
            cell: (item) => <span>{item?.notes}</span>,
            sortingField: "endDate",
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={LICENSE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`license/${selectedRows?.at(0)?.id}/edit`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`license/add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title
                level={3}
                style={{ marginTop: "0", fontSize: "22px" }}
              >
                License{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${provider?.providerLicenses?.length || 0
                  })`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteProviderLicense({
                providerId: providerId,
                licenseId: selectedRows?.at(0)?.id + "",
              }).unwrap();
              toastContext?.openSuccessNotification({
                message: `License deleted successfully`,
                placement: "topRight",
              });
            } catch (error: any) {
              toastContext?.openErrorNotification({
                message: error?.data?.errorMessage || `Failed to delete license`,
                placement: "topRight"
              })
            }
            spinnerContext?.hidespinner();
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete License"
        content={
          <Typography.Text>
            License Number :{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.licenseNumber}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this license?"
      />
    </>
  );
}
