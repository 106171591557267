import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { ActiveProps } from "./sidebar.interface";
import { menuItemStyle, sidebarStyles } from "./sidebar.styles";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import { Images } from "../../common/images";
import { PATH_DASHBOARD } from "../../routes/path";

export default function Sidebar({ activeHref, collapseSideBar }: ActiveProps) {
  const navigate = useNavigate();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const menuItems = [
    {
      key: PATH_DASHBOARD.dashboard.list,
      icon: <img src={Images.dashboard} alt="Dashboard" />,
      label: "Dashboard",
    },
    {
      key: PATH_DASHBOARD.provider.manageprovider.list,
      icon: <img src={Images.providers} alt="Providers" />,
      label: "Providers",
    },
    {
      key: PATH_DASHBOARD.safe.list,
      icon: <img src={Images.safe} alt="Safe" />,
      label: "Safe",
    },
    {
      key: PATH_DASHBOARD.managefacilities.list,
      icon: <img src={Images.facilities} alt="Facilities" />,
      label: "Facilities",
    },
    ...(!!organization?.organizationServiceLimits?.digitalForms &&
    !!organization?.organizationServiceLimits?.eSignatures
      ? [
          {
            key: PATH_DASHBOARD.digitalforms.list,
            icon: <img src={Images.readyForm} alt="Ready Forms" />,
            label: "Ready Forms",
          },
        ]
      : []),
    // ...(!!organization?.organizationServiceLimits?.privileges
    // ? [
    {
      key: PATH_DASHBOARD.affiliations.list,
      icon: <img src={Images.privileges} alt="Affiliations" />,
      label: "Affiliations",
    },
    // ]
    // : []),
    /*   ...(!!organization?.organizationServiceLimits?.privileges
      ? [
          {
            key: PATH_DASHBOARD.privileges.list,
            icon: (
              <MonitorOutlined
                style={{
                  fontSize: "24px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ),
            label: "Privileges",
          },
        ]
      : []), */
    ...((!!organization?.organizationServiceLimits?.digitalForms &&
      !!organization?.organizationServiceLimits?.eSignatures) ||
    !!organization?.organizationServiceLimits?.documentRequest
      ? [
          {
            key: PATH_DASHBOARD.eSignatures.list,
            icon: <img src={Images.eSignature} alt="E-Signatures" />,
            label: "Requests",
          },
        ]
      : []),
    ...(!!organization?.organizationServiceLimits?.contracts
      ? [
          {
            key: PATH_DASHBOARD.contracts.list,
            icon: <img src={Images.contracts} alt="Contracts" />,
            label: "Contracts",
          },
        ]
      : []),
    ...(!!organization?.organizationServiceLimits?.payerEnrollments
      ? [
          {
            key: PATH_DASHBOARD.payerenrollment.list,
            icon: <img src={Images.payerEnrollment} alt="Payer Enrollments" />,
            label: "Payer Enrollments",
          },
        ]
      : []),
    ...(!!organization?.organizationServiceLimits?.credentialPackages
      ? [
          {
            key: PATH_DASHBOARD.credential.packages.list,
            icon: (
              <img src={Images.credentialPackages} alt="Credential Packages" />
            ),
            label: "Credential Packages",
          },
        ]
      : []),
    ...(!!organization?.organizationServiceLimits?.workflows
      ? [
          {
            key: PATH_DASHBOARD.manage.workflows.list,
            icon: <img src={Images.workFlows} alt="Workflows" />,
            label: "Workflows",
          },
        ]
      : []),
    ...(!!organization?.organizationServiceLimits?.tasks
      ? [
          {
            key: PATH_DASHBOARD.tasks.list,
            icon: <img src={Images.tasks} alt="Tasks" />,
            label: "Tasks",
          },
        ]
      : []),
    /*   {
      key: PATH_DASHBOARD.gme.list,
      icon: (
        <BookOutlined
          style={{
            fontSize: "24px",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ),
      label: "GME",
    }, */
    {
      key: "reports",
      icon: <img src={Images.reports} alt="Reports" />,
      label: "Reports",
      children: [
        {
          key: PATH_DASHBOARD.reports.providerroatser.list,
          label: "Providers Report",
        },
        ...(!!organization?.organizationServiceLimits?.payerEnrollments
          ? [
              {
                key: PATH_DASHBOARD.reports.payerenrollment.list,
                label: "Payer Enrollments",
              },
            ]
          : []),
        {
          key: PATH_DASHBOARD.reports.provideraffiliation.list,
          label: "Provider Affiliations Report",
        },
        {
          key: PATH_DASHBOARD.reports.alldocuments.list,
          label: "All Documents",
        },
        {
          key: PATH_DASHBOARD.reports.expiringdocuments.list,
          label: "Expiring Documents",
        },
        {
          key: PATH_DASHBOARD.reports.expireddocuments.list,
          label: "Expired Documents",
        },
      ],
    },
    {
      key: "manage",
      icon: <img src={Images.manage} alt="Manage" />,
      label: "Manage",
      children: [
        ...(!!organization?.organizationServiceLimits?.payerEnrollments
          ? [
              {
                key: PATH_DASHBOARD.payers.list,
                label: "Payer Setup",
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.payerEnrollments
          ? [
              {
                key: PATH_DASHBOARD.payergroup.list,
                label: "Payer Network Setup",
              },
            ]
          : []),
        {
          key: PATH_DASHBOARD.facilitygroup.list,
          label: "Facility Group",
        },
        ...(!!organization?.organizationServiceLimits?.workflows
          ? [
              {
                key: PATH_DASHBOARD.manage.workflowTemplates.list,
                label: "Workflow Templates",
              },
            ]
          : []),
        {
          key: PATH_DASHBOARD.manage.licensetype.list,
          label: "Provider Licenses",
        },
        {
          key: PATH_DASHBOARD.manage.categories.list,
          label: "Document Categories",
        },
        ...(!!organization?.organizationServiceLimits?.credentialPackages
          ? [
              {
                key: PATH_DASHBOARD.credential.templates.list,
                label: "Credential Templates",
              },
            ]
          : []),
        ...(!!organization?.organizationServiceLimits?.credentialPackages
          ? [
              {
                key: PATH_DASHBOARD.manage.locations.list,
                label: "Locations",
              },
            ]
          : []),
      ],
    },
  ];

  const handleMenuClick = (e: any) => {
    collapseSideBar();
    navigate(e.key);
  };

  return (
    <Menu
      theme="dark"
      mode="vertical"
      defaultSelectedKeys={[activeHref]}
      items={menuItems.map((item) => ({ ...item, style: menuItemStyle.item }))}
      onClick={handleMenuClick}
      style={sidebarStyles}
      className="sidebar-menu"
    />
  );
}
